/*
  App.css contains app wide styling as well as some styling that has not been seperated out.
  Feel free to comment/organize/seperate any styling. Other styles can be found in the components/componentStyling folder
  Namely Video.css, Forms.css, and Cards.css.
 */

.App {
  text-align: center;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  word-break: break-all;
  word-wrap: break-word;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #bc0b0e;
  color: #dbcece;
  cursor: pointer;
  border-radius: .5rem;
  border: #dbcece 1px solid;
  padding: 1%;
}
.back-to-top:hover {
  background:#dbcece;
  color: #bc0b0e;
  border:1px solid #bc0b0e;
  transition:.3s;
}
.back-to-top span {
  font-size: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  background: #f2f2f2;
  margin-bottom:300px;
}
a {
  color:darkblue;
}
p {
  font-size:1.1rem;
}
.title {
  font-size: 2rem;
}
.logo {
  width:30%;
}
.loginDiv {

}
.dashLinkDiv {
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap:15px;
}
.input, .submitButton {
  display:block;
  margin:auto;
  margin-top:1%;
}
.input {
  width:60%;
  height:25px;
  border-radius:.5rem;
}
.exportDiv {
  margin:5%;
}
/* Buttons */
.submitButton {
  width:35%;
  color:black;
  cursor: pointer;
  background:white;
  border:black 1px solid;
  border-radius:.5rem;
  padding:1%;
  font-size:1.15rem;
  text-decoration: underline;
}
.submitButton:hover {
  background:black;
  border:white 1px solid;
  color:white;
  transition:.2s;
}
.longButton {
  width:60%;
  font-size:1rem;
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.eventTopLink {
  margin:1%;
}
.navLink {
  cursor: pointer;
  text-decoration: underline;
  margin:2%;
}
.eventDiv {
  display:flex;

}
.eventTopLink {
  cursor:pointer;
  text-decoration: underline;
  color:blue;
  font-size:1.2rem;
  border:solid blue 1px;
  padding:1%;
  border-radius: .5rem;
  background: white;
}
.eventTopLink:hover {
  background:blue;
  color:white;
  border:white 1px solid;
  border:solid white 1px;
  transition:.2s;
}
/* Message styling */
.messageSuccess, .messageError{
  width:75%;
  margin:auto;
  border-radius: .5rem;
}
.buttonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.messageSuccess {
  background:green;
}
.messageError {
  background:red;
}
.message {
  color:white;
  padding:2%;
}
.siteLink {
  font-size:1.35rem;
  margin:1%
}
.spanHeader {
  font-weight: 700;
  font-size:1.1rem;
}
.eventInfo, .eventSubtitle {
  font-size:1.1rem;
}
.eventTitle {
  font-size:1.4rem;
  font-weight:750;
}

/*Chat Styling */
.messageOuterContainer {
  padding-top:2%;
}
.messageInput {
  padding-right: 50px;
  height: 30px;
  width: 75%;
  padding: 1% 2%;
  border-radius: 0 0 .5rem .5rem
}
.messageSendButton {
  margin-left: -50px;
  cursor: pointer;
}
.messageContainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.messageHeaderDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2%;
}
.messageHeaderText {
  color: white;
  margin: 0 2%;
}
.avatarDiv {
  float: right;
}
.avatar {
  border-radius: 30px;
  margin-top: 20px;
}
.chatBox {
  position: relative;
  width:90%;
  margin:auto;
  background: black;
  height: 600px;
  border-radius: .5rem;
  overflow-y:auto;
}

.messageInputBox {
  width: 90%;
  margin: auto;
}
.bubble {
  padding: 1% 3%;
  background: white;
  border-radius: .75rem;
  color:black;
  display: inline-block;
  float: left;
  min-width: 40%;
  max-width: 85%;
  margin-bottom: 5%;
  border-radius: 30px 20px 20px 5px;
}
.bubbleText {
  text-align: left;
}
.bubbleFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bubbleFooterText {
  font-size: .7rem;
}
.chatDeleteButton {
  cursor: pointer;
  color:blue;
  text-decoration: underline;
}

/* Nav bar styling */
.navBar {
  display: flex;
  flex-direction:row;
  justify-content: space-evenly;
  width:100%;
  height: 30px;
  background: black;
}
.navLogOut {
  cursor:pointer;
}
.navSection {
  margin:auto;
}
.navText {
  color:white;
}
.cardHeader {
  font-weight:bold;
  margin-bottom: 0;
}
.formSectHeader {
  margin-bottom: 0;
}
/* Images */
.image {
  width: 70%;
}
.loaderDiv {
  margin-top:5%;
}

.queryP {
  color:black;
  font-size:1.5rem;
}
.bookingInfo {
  border:1px white solid;
  border-radius:.5rem;
  width:80%;
  margin:auto;
  background:white;
  color:black;
  padding:1%;
}
.bookingButtons {
  padding:.5%;
  cursor: pointer;
}
.bookingOuterDiv {
  margin-bottom:2%;
}
.userBookingList {
  width:50%;
  max-height:300px;
  overflow:scroll;
  overflow-x:hidden;
  overflow-y:auto;
  width:70%;
  margin:auto;
  border:black 1px solid;
}
.bookingUserP {
  cursor:pointer;
  color:#3333ff;
  text-decoration:underline;
}
.userGridBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.userDiv {
  background: white;
  color:black;
  border:1px green solid;
  border-radius:.5rem;
  padding:2%;
}
button {
  cursor: pointer;
}
/* Ad styling */
.instructionText {
  width:75%;
  margin:auto;
  margin-top: 0;
  margin-bottom:35px;
}
.galleryButton {
  text-decoration: underline;
  cursor:pointer;
  color:blue;
  font-size: 1.1rem;
}
/* Dates Styling */
.datesDiv {
  display: inline-block;
  background:white;
  color:black;
  padding:1%;
  border-radius: .5rem;
  margin-top:2%
}
.datesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
/* Images styling */
.galleryDiv {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
}
@media only screen and (min-width:400px) {
  .submitButton {
    width:35%
  }
  .longButton {
    width:50%;
  }
}
@media only screen and (min-width:500px) {

}
@media only screen and (min-width:620px) {
  .chatBox, .messageInputBox {
    width:80%;
  }
}
@media only screen and (min-width:768px) {
  .submitButton {
    width:15%
  }
  .longButton {
    width:30%;
    font-size:1rem;
  }
  .chatBox, .messageInputBox {
    width:75%;
  }
}
@media only screen and (min-width:1100px) {
  .image {
    width:60%;
  }
  .chatBox, .messageInputBox {
    width:60%;
  }
}
@media only screen and (min-width:1500px) {

}
