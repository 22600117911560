/* Styling for the video components. */

.inEventInfoDiv {
  margin-bottom:0;
}

h1,h4{
    text-align: center;
}
.me{
    position: relative;
    width: 50%;
    margin: 0 auto;
    display: block;
}
.me video{
    position: relative !important;
}
.remote-container{
    display: flex;
}
.remote-container video{
    height: auto;
    position: relative !important;
}
.iFrame {
  width:300px;
  height:450px;
}

.videoEventCard {
  background:cadetblue;
  color:white;
  border-radius: .5rem;
  margin:auto;
  margin-bottom: 5%;
  width:90%;
  padding:2%;
  font-size:1.2rem;
}
/* Timer styling */
.timerDiv {
  width:80%;
  margin:auto;
  padding:.5%;
  border-radius: .5rem;
  color:aqua;
}
.timerDivWarn {
  background:goldenrod;
  color:black;
}
.timerDivOver {
  background:red;
  color:black;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/* video table styling */
.tableOuterRow {
  cursor:pointer;
  background: lightgray;
  color:black;
  font-size:1rem;
  margin-top:2%;
}
.tableOuterRowFocus {
  cursor:pointer;
  background:indigo;
  color:white;
  font-size:1rem;
  margin-top:2%;
}
.table {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap:wrap;
  width:80%;
  margin: auto;
}
.tableInnerRow {
  margin:0;
}
.tableCell {
  border: 1px solid black;
  border-collapse: collapse;
  padding:1%;

}
.tableCellFocus {
  border: 1px solid white;
  border-collapse: collapse;
  padding:1%;
}
.eventTitle {
  font-size:1.3rem;

}
.eventDate {
  font-size:1.1rem;
  font-weight: bold;
}
.tableLink {
  cursor:pointer;
}

table {
  width:80%;
  margin:auto;
}
th, td {
  padding:1%;
}
.tableLink {
  cursor: pointer;
  text-decoration: underline;
}
.iFrameDiv {
  width:100%;
  margin: auto;
}
@media only screen and (min-width:500px) {
  .iFrame {
    width:450px;
    height:450px;
  }
}
@media only screen and (min-width:620px) {
  .iFrame {
    width:550px;
    height:550px;
  }
}
@media only screen and (min-width:768px) {
  .iFrame {
    width:700px;
    height:500px;
  }
}
  @media only screen and (min-width:1000px) {
    .iFrame {
      width:900px;
      height:600px;
    }
}
@media only screen and (min-width:1100px) {
  .iFrame {
    width:1000px;
    height:650px;
  }
}
@media only screen and (min-width:1500px) {
  .iFrame {
    width:1200px;
    height:750px;
  }
}
