/*
  All styling specific to event, band and user cards.
 */

.cardDiv {
  background:cadetblue;
  color:white;
  border-radius: .5rem;
  width:80%;
  margin: auto;
  padding:2%;
  margin-bottom:3%;
}
.viewEventP {
  font-size:1.15rem;
}

.eventDate {
  font-size:1.1rem;
  font-weight: bold;
}
.submitButtonCard, .deleteButtonCard, .toggleButtonCard, .invSubmitButtonCard, .invToggleButtonCard{
  margin:2%;
  padding:2%;
  border-radius: .5rem;
  background:white;
  color:blue;
  border: 1px solid blue;
  cursor:pointer;
  transition:.3s;
  font-size:1.2rem
}
.invToggleButtonCard:hover {
  color:green;
  background:white;
  border:1px solid green;
}
.toggleButtonCard {
  color:green;
  background:white;
  border:1px solid green;
}
.invToggleButtonCard{
  color:white;
  background:green;
  border:1px solid white;
  transition:.3s;
}
.toggleButtonCard:hover {
  color:white;
  background:green;
  border:1px solid white;
  transition:.3s;
}
.invSubmitButtonCard {
  background:blue;
  color:white;
  border: 1px solid white;
}
.invSubmitButtonCard:hover {
  background:white;
  color:blue;
  border: 1px solid blue;
}
.submitButtonCard:hover {
  background:blue;
  color:white;
  border: 1px solid white;
}
.deleteButtonCard {
  color:red;
  background:white;
  border:1px solid red;
}
.deleteButtonCard:hover {
  color:white;
  background:red;
  border:1px solid white;
  transition:.3s;
}
