/* Styling for forms used to create, events, users and bands.
  login forms are in app.css
 */

.editInput{
  width:50%;
  height:2rem;
  border-radius:.5rem;
  font-size:1.25rem;
}

.eventTypeButton {
  padding: 1% 2%;
  border-radius: .5rem;
  cursor:pointer;
  background: white;
  font-size:1.2rem;
  border:1px black solid;
  margin-bottom:2%;
}
.allAccessDiv {
  margin-top:4%;
}
.select {
  width:50%;
  margin: auto;
}
.eventInput {
  margin-top:2%;
  margin-bottom:2%;
  height:2rem;
  border-radius:.25rem;
  width:50%;
  border:1px solid blue;
  padding-left:1%;
  font-size:1rem;
}
.eventDesc, .bandDescInput {
  height:100px;
  font-size:1.15rem;
  width:90%;
}
.bandDescInput {
  height:200px;
}
.outerTimeDiv {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding:2%;
}
.innerTimeDiv {
  display: flex;
  flex-direction: column;
}
.timePicker {
  background:white;
}
.photoDiv {
  margin-bottom: 10%;
  font-size:1.1rem;
  width:80%;
  margin:auto;
}
.streamSelect {
  margin-top:2%;
}
.eventTypeButton:hover {
  background:black;
  color:white;
  border: 1px white solid;
}
.datepicker,.timePicker {
  height:2rem;
  margin:0;
}
.dateDiv {
  display: flex;
  flex-direction: column;
  margin-top:2%;
  width:50%;
  margin:auto;
  padding:2%;
}
.uploadDiv {
  margin:auto;
  margin-bottom:8%;
  background:lightgoldenrodyellow;
  border-radius:.5rem;
  padding:2%;
  padding-top:1%;
  width:80%;
}
input[type="file"] {
  font-size:1.1rem;
  background:slategray;
  padding:2%;
}
@media only screen and (min-width:400px) {
  .input {
    width:45%;
  }
  .eventInput, .select {
    width:70%;
  }
}
@media only screen and (min-width:768px) {
  .input {
    width:35%;
  }
  .eventInput, .select {
    width:60%;
  }
}
@media only screen and (min-width:1000px) {
.input {
  width:25%;
}
.eventInput, .select {
  width:50%;
}
}
